<template>
  <div v-if="isVisible">
    <!-- White overlay -->
    <div class="overlay"></div>

    <div class="scrapbook-container">
      <div class="scrapbook-header">
        <h2>{{ $t('compose_scrapbook') }}</h2>
        <button @click="closeScrapbook" class="close-btn">
          <img src="@/assets/close_icon.png" alt="Close" />
        </button>
      </div>
      <div class="scrapbook-content">
        <!-- Gray box in the middle or uploaded image -->
        <div v-if="!imageUrl" class="upload-box" @click="triggerFileUpload">
          <p>{{ $t('upload_photo') }}</p>
        </div>

        <div v-else class="upload-box" @click="triggerFileUpload">
          <div class="uploaded-image-wrapper">
            <img :src="imageUrl" alt="Uploaded" />
          </div>
        </div>

        <!-- Hidden file input -->
        <input 
          type="file" 
          ref="fileInput" 
          accept="image/*" 
          @change="handleFileUpload" 
          style="display: none" 
        />

        <!-- Hotspots -->
        <div
          v-for="hotspot in hotspots"
          :key="hotspot.id"
          class="hotspot-image"
          :style="{ zIndex: hotspot.zIndex, top: `${hotspot.y}px`, left: `${hotspot.x}px` }"
          @mousedown="startDragging($event, hotspot)"
        >
          <img :src="getHotspotImage(hotspot)" alt="Hotspot" />
        </div>
      </div>
      <div class="scrapbook-footer">
        <button class="upload-btn" @click="triggerFileUpload">{{ $t('upload_new_image') }}</button>
        <button class="share-btn">{{ $t('share') }}</button>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, onMounted } from 'vue';
import emitter from '@/eventBus.js';
import whitelabel from '@/assets/whitelabel.json';

const isVisible = ref(false);
const hotspots = ref([]);
const collectedHotspots = ref([]);
const imageUrl = ref(''); // To store the uploaded image URL

// Open and close scrapbook functions
const openScrapbook = () => {
  isVisible.value = true;
  loadHotspots();
};

const closeScrapbook = () => {
  isVisible.value = false;
};

// Trigger file upload when upload-box or uploaded image is clicked
const triggerFileUpload = () => {
  const fileInput = document.querySelector('input[type="file"]');
  fileInput.click();
};

// Handle the file upload and set the image URL
const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      imageUrl.value = reader.result; // Set the image URL for preview
    };
    reader.readAsDataURL(file); // Read file as DataURL for preview
  }
};

// Load hotspots with initial positioning
const loadHotspots = () => {
  hotspots.value = whitelabel.hotspots;

  hotspots.value.forEach((hotspot) => {
    const cookieKey = `collected-hotspot-${hotspot.id}`;
    const isCollected = document.cookie
      .split('; ')
      .find((row) => row.startsWith(cookieKey))
      ?.split('=')[1];
    if (isCollected) {
      collectedHotspots.value.push(hotspot.id);
    }
  });

  // Use the container's actual width and height
  const containerHeight = window.innerHeight * 0.9;
  const containerWidth = (containerHeight / 16) * 9;

  // Define the center of the gray box
  const centerX = containerWidth / 4;
  const centerY = containerHeight / 4;

  const maxRadius = Math.min(containerWidth, containerHeight) * 0.5; // Adjust as needed

  const totalHotspots = hotspots.value.length;

  hotspots.value.forEach((hotspot, index) => {
    // Calculate the angle for each hotspot to evenly distribute them around the circle
    const angle = (index / totalHotspots) * 2 * Math.PI;

    // Calculate the x and y coordinates using polar to Cartesian transformation
    const radius = maxRadius; // Adjust the radius if needed

    hotspot.x = centerX + radius * Math.cos(angle);
    hotspot.y = centerY + radius * Math.sin(angle);
    hotspot.zIndex = index; // Set initial z-index
    console.log("hotspot placed at:", angle, radius);
  });
};


const getHotspotImage = (hotspot) => {
  return require(`@/assets/${hotspot.image}`);
};

// Handle drag and drop functionality
const startDragging = (event, hotspot) => {
  event.preventDefault();

  const initialX = event.clientX;
  const initialY = event.clientY;
  const initialTop = hotspot.y;
  const initialLeft = hotspot.x;

  const onMouseMove = (moveEvent) => {
    hotspot.x = initialLeft + (moveEvent.clientX - initialX);
    hotspot.y = initialTop + (moveEvent.clientY - initialY);
    hotspot.zIndex = Math.max(...hotspots.value.map(h => h.zIndex)) + 1; // Bring to front
  };

  const onMouseUp = () => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);
};

onMounted(() => {
  emitter.on('scapbook-opened', openScrapbook);
});
</script>


<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999;
}

.scrapbook-container {
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  height: 90%;
  width: calc((90vh / 16) * 9);
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.scrapbook-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  border-bottom: 1px solid #ccc;
}

.scrapbook-content {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.upload-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  font-weight: bold;
  text-align: center;
  border: 2px dashed #999;
  cursor: pointer;
  overflow: hidden; /* Ensures the image doesn't overflow the upload box */
}

.uploaded-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.uploaded-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image fits within the box without distorting */
  display: block;
}

.hotspot-image {
  position: absolute;
  cursor: move;
}

.scrapbook-footer {
  display: flex;
  justify-content: space-between;
  padding: 25px;
}

.upload-btn {
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}

.share-btn {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
}

.close-button {
  color: #000000;
  cursor: pointer;
  border: none;
  background-color: #ffffff;
  font-size: 1.5rem;
}
</style>
