<template>
  <div class="hotspot-details-overlay" v-if="isVisible" @wheel.stop="preventZoom" @touchmove.stop="preventZoom">
    <div class="hotspot-details-container" ref="scrollableContainer">
      <!-- Close button inside the modal -->
      <button class="close-button" @click="closeHotspotDetails">X</button>
      
      <h1 v-if="hotspot?.details?.title">{{ hotspot.details.title }}</h1>

      <!-- Dynamic layout rendering -->
      <div v-for="(row, rowIndex) in hotspot.details.layout" :key="rowIndex" class="row">
        <div v-for="(item, itemIndex) in row" :key="itemIndex" :class="getItemClass(row.length)" class="layout-item">
          <!-- Render video, image, or text based on the asset type -->
          <div v-if="item.asset.includes('video')" :style="getAspectRatioStyle(item.aspectRatio)" class="video-placeholder">
            <i class="fas fa-video"></i>
          </div>
          <div v-else-if="item.asset.includes('image')" :style="getAspectRatioStyle(item.aspectRatio)" class="image-placeholder">
            <i class="fas fa-image"></i>
          </div>
          <div v-else class="text-placeholder" :style="{ textAlign: item['text-align'] || 'left' }">
            <p>{{ item.asset }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import emitter from '@/eventBus.js';

const isVisible = ref(false);
const hotspot = ref(null);
const scrollableContainer = ref(null);

const openHotspotDetails = (selectedHotspot) => {
  isVisible.value = true;
  hotspot.value = selectedHotspot;
};

const closeHotspotDetails = () => {
  isVisible.value = false;
  hotspot.value = null;
};

// Prevent zoom action in AFrame scene while scrolling inside HotspotDetails
const preventZoom = (event) => {
  if (scrollableContainer.value?.scrollHeight > scrollableContainer.value.clientHeight) {
    event.stopPropagation();
  }
};

// Method to calculate column width based on the number of items in a row
const getItemClass = (itemCount) => {
  return {
    'col-12': itemCount === 1,
    'col-6': itemCount === 2,
    'col-4': itemCount === 3,
    'col-3': itemCount === 4,
  };
};

// Dynamically calculate aspect ratio style based on asset ratio
const getAspectRatioStyle = (aspectRatio) => {
  const [width, height] = aspectRatio.split(':').map(Number);
  const paddingBottom = (height / width) * 100;
  return {
    'padding-bottom': `${paddingBottom}%`,
    position: 'relative',
    width: '100%',
    height: 0,
  };
};

onMounted(() => {
  emitter.on('hotspot-selected', openHotspotDetails);
});

onUnmounted(() => {
  emitter.off('hotspot-selected', openHotspotDetails);
});
</script>

<style scoped>
.hotspot-details-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;
  overflow-y: auto;
  font-family: 'Arial', sans-serif; /* Global font for the modal */
}

.hotspot-details-container {
  position: relative;
  width: 70%;
  height: 75%;
  background-color: #fff;
  padding: 60px;
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1003;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000000;
  cursor: pointer;
  border: none;
  background-color: #ffffff;
  font-size: 1.5rem;
}

.close-button:hover {
  background-color: #a98b8b;
  border: none;
  border-radius: 2px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.layout-item {
  padding-right: 5%;
  box-sizing: border-box;
}

.video-placeholder, .image-placeholder {
  background-color: #000;
  border: 2px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  color: #fff;
  font-size: 2rem;
}

h1 {
  font-size: 3rem; /* Font size for the title */
  margin-top: 0;
}

.text-placeholder {
  color: #333;
  white-space: pre-line; /* Preserves line breaks in the text */
  font-size: 1rem;
  line-height: 1.5;
}

p {
  margin: 0;
}

.col-12 {
  width: 100%;
}

.col-6 {
  width: 50%;
}

.col-4 {
  width: calc(33.33%);
}

.col-3 {
  width: calc(25%);
}
</style>
