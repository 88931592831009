import { createI18n } from 'vue-i18n';
import Cookies from 'js-cookie';
import en from '@/assets/languages/en.json'; // Import English language file
import it from '@/assets/languages/it.json'; // Import Italian language file
import fr from '@/assets/languages/fr.json'; // Import French language file

// Get saved language from cookie or fallback to default
const savedLanguage = Cookies.get('selectedLanguage') || 'EN';

// Create the i18n instance
const i18n = createI18n({
  legacy: false, // Use Composition API mode in Vue 3
  locale: savedLanguage, // Set locale from saved cookie or default
  fallbackLocale: 'EN', // Fallback language
  globalInjection: true, // Allow `t` method globally
  messages: {
    EN: en, // English language messages
    IT: it, // Italian language messages
    FR: fr, // French language messages
  },
});

export default i18n;
