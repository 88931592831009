<template>
    <div class="inventory-content">
      <div 
        v-for="hotspot in hotspots" 
        :key="hotspot.id" 
        class="hotspot-item"
      >
        <img 
          :src="getHotspotImage(hotspot)" 
          :alt="hotspot.details.title" 
          :class="{'collected': isCollected(hotspot.id), 'uncollected': !isCollected(hotspot.id)}" 
        />
      </div>

      <button class="scrapbook-button" @click="openScrapbook">
        {{ $t('complete_scrapbook') }}
      </button>
    </div>
</template>
  
<script setup>
  import { ref, onMounted } from 'vue';
  import whitelabel from '@/assets/whitelabel.json';
  import emitter from '@/eventBus.js';

  const hotspots = ref([]);
  const collectedHotspots = ref([]);
  
  onMounted(() => {
    loadHotspots();
  });

  function openScrapbook() {
    emitter.emit('scapbook-opened');
  }
  
  const loadHotspots = () => {
    hotspots.value = whitelabel.hotspots;
  
    hotspots.value.forEach(hotspot => {
      const cookieKey = `collected-hotspot-${hotspot.id}`;
      const isCollected = document.cookie.split('; ').find(row => row.startsWith(cookieKey))?.split('=')[1];
      if (isCollected) {
        collectedHotspots.value.push(hotspot.id);
      }
    });
  };
  
  const getHotspotImage = (hotspot) => {
    return require(`@/assets/${hotspot.image}`);
  };
  
  const isCollected = (hotspotId) => {
    return collectedHotspots.value.includes(hotspotId);
  };
  </script>
  
<style scoped>
  .inventory-content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .hotspot-item {
    width: calc(33.333% - 10px);
    box-sizing: border-box;
  }
  
  .hotspot-item img {
    width: 80%;
    height: auto;
    object-fit: cover;
    transition: opacity 0.3s ease;
  }
  
  .hotspot-item img.uncollected {
    opacity: 0.3;
    filter: grayscale(100%);
  }

  .scrapbook-button {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    width: 25%;
    text-align: center;
  }
</style>
  