<template>
  <div class="parent-container">
    <!-- Toggle Debug Panel Button -->
    <button class="toggle-button" @click="toggleDebugPanel">Toggle Debug Panel</button>

    <!-- Debug Panel (Draggable) -->
    <div v-if="showDebugPanel"
         class="debug-panel"
         :style="{ top: panelPosition.top + 'px', left: panelPosition.left + 'px' }"
         @mousedown="startDrag">
      <h3>Debug Controls</h3>

      <!-- Each option is identified by its key and used to generate the corresponding input field or group of fields. -->
      <div v-for="(option, key) in viewerOptions" :key="key">
        <!-- Display a header if the option is not an object (like a number or array). -->
        <h4 v-if="!isObject(option)" :for="key">{{ formatLabel(key) }}:</h4>
        
        <!-- v-model.number binds the value and automatically casts it to a number. -->
        <input 
          v-if="isNumeric(option)" 
          type="number" 
          v-model.number="viewerOptions[key]" 
          @input="updateViewerOption(key)" 
          :min="getMinValue(key)" 
          :max="getMaxValue(key)"
        />

        <!-- Each element in the array is rendered as an individual input field. -->
        <div v-if="isArray(option)" class="array-inputs">
          <div v-for="(value, index) in option" :key="index">
            <label :for="key">{{ formatLabel(key) }} {{ index + 1 }}:</label>
            <input 
              type="number" 
              v-model.number="viewerOptions[key][index]" 
              @input="updateViewerOption(key, index)" 
            />
          </div>
        </div>

        <!-- These options contain nested properties that are also rendered as input fields. -->
        <div v-if="isObject(option)" class="object-inputs">
          <h4>{{ formatLabel(key) }}:</h4>
          <div v-for="(subOption, subKey) in option" :key="subKey">
            <label :for="subKey">{{ formatLabel(subKey) }}:</label>
            <input 
              type="number" 
              v-model.number="viewerOptions[key][subKey]" 
              @input="updateViewerOption(key, subKey)" 
            />
          </div>
        </div>
      </div>

      <div class="hotspots-inputs">
        <h4>Hotspots</h4>
        <div v-for="(hotspot, index) in hotspots" :key="index" class="hotspot-item">
          <h5>Hotspot {{ index + 1 }}</h5>
          <label>Position:</label>
          <input 
            type="text" 
            v-model="hotspots[index].position" 
            @input="updateHotspotPosition(index)"
          />
          <label>Rotation:</label>
          <input 
            type="text" 
            v-model="hotspots[index].rotation" 
            @input="updateHotspotPosition(index)"
          />
          <label>Title:</label>
          <input 
            type="text" 
            v-model="hotspots[index].details.title" 
          />
          <label>Description:</label>
          <textarea v-model="hotspots[index].details.description"></textarea>
        </div>
      </div>
    </div>

    <!-- VR Scene -->
    <a-scene>
      <!-- Assets to be used in the scene, such as the panorama image. -->
      <a-assets>
        <img id="panoramaImage" :src="imagePath" alt="Panorama Image" @load="onImageLoad" @error="onImageError"/>
      </a-assets>

      <!-- The sky element displays the 360° panorama image as the scene's background. -->
      <!-- The rotation attribute is dynamically bound to the viewerOptions.rotation array. -->
      <a-sky
        :src="'#panoramaImage'"
        :rotation="viewerOptions.rotation.join(' ')"
        :radius="viewerOptions.radius"
      ></a-sky>

      <!-- The camera element represents the viewer's perspective in the scene. -->
      <!-- The position and field of view (fov) are dynamically set based on viewerOptions. -->
      <a-camera
        id="camera"
        :position="viewerOptions.position.join(' ')"
        :fov="viewerOptions.fov"
        look-controls
        cursor="rayOrigin: mouse"
      ></a-camera>

      <!-- Hotspots -->
      <a-image
        v-for="(hotspot, index) in hotspots"
        :key="index"
        :src="hotspotImageSrc"
        :position="hotspot.position"
        :rotation="hotspot.rotation"
        class="clickable"
        @click="showHotspotDetails(index)"
      ></a-image>

    </a-scene>
  </div>
</template>


<script>
import 'aframe';
import whitelabel from '@/assets/whitelabel.json';
import * as THREE from 'three';
import emitter from '@/eventBus.js';
import Cookies from 'js-cookie';

export default {
  name: 'VRScene',
  data() {
    return {
      imagePath: '',
      showDebugPanel: false,
      panelPosition: { top: 50, left: 50 },
      isDragging: false,
      dragStart: { x: 0, y: 0 },
      viewerOptions: {
        ...whitelabel.viewerOptions,
        rotation: whitelabel.viewerOptions.rotation.split(' ').map(parseFloat),
        position: whitelabel.viewerOptions.position.split(' ').map(parseFloat),
        radius: whitelabel.viewerOptions.radius,
        scrollLimits: this.getScrollLimits(),
      },
      hotspotImageSrc: '',
      hotspots: whitelabel.hotspots.map(hotspot => ({
        ...hotspot,
        position: hotspot.position.split(' ').map(parseFloat).join(' '),
        rotation: hotspot.rotation.split(' ').map(parseFloat).join(' ')
      })),
      originalTickFunction: null, // To store the original tick function
    };
  },
  created() {
    try {
      this.imagePath = require(`@/assets/${whitelabel.panoramaImagePath}`);
      this.hotspotImageSrc = require(`@/assets/${whitelabel.hotspotImageSrc}`);
    } catch (error) {
      console.error("Error resolving image path:", error);
    }
  },
  mounted() {
    // Ensure the scene elements are ready before applying initial values
    this.$nextTick(() => {
      this.setupCamera();
      this.applyInitialValues(); // Apply initial FOV, position, and scroll limits
      this.setupZoomListeners(); 
    });
  },
  methods: {
    toggleDebugPanel() {
      this.showDebugPanel = !this.showDebugPanel;
    },
    startDrag(event) {
      this.isDragging = true;
      this.dragStart.x = event.clientX - this.panelPosition.left;
      this.dragStart.y = event.clientY - this.panelPosition.top;

      document.addEventListener('mousemove', this.drag);
      document.addEventListener('mouseup', this.stopDrag);
    },
    drag(event) {
      if (this.isDragging) {
        this.panelPosition.left = event.clientX - this.dragStart.x;
        this.panelPosition.top = event.clientY - this.dragStart.y;
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('mouseup', this.stopDrag);
    },
    getScrollLimits() {
      return this.isMobileDevice() 
        ? { ...whitelabel.viewerOptions.scrollLimits.mobile } 
        : { ...whitelabel.viewerOptions.scrollLimits.default };
    },
    onImageLoad() {
      console.log("Image successfully loaded:", this.imagePath);
    },
    onImageError(error) {
      console.error("Error loading image:", error);
    },
    isMobileDevice() {
      return /Mobi|Android/i.test(navigator.userAgent);
    },
    setupCamera() {
      const cameraEl = document.querySelector('#camera');
      if (cameraEl) {
        cameraEl.setAttribute('fov', this.viewerOptions.fov);
        cameraEl.setAttribute('position', `${this.viewerOptions.position[0]} ${this.viewerOptions.position[1]} ${this.viewerOptions.position[2]}`);
        cameraEl.setAttribute('rotation', `${this.viewerOptions.rotation[0]} ${this.viewerOptions.rotation[1]} ${this.viewerOptions.rotation[2]}`);
      }
    },
    applyInitialValues() {
      // Apply initial camera settings
      this.updateCameraSettings();
      this.updateSkyRotation();
      this.updateCameraPosition();
      this.applyRotationLimits();
    },
    updateViewerOption(key, subKey = null) {
      if (subKey !== null) {
        this.viewerOptions[key][subKey] = parseFloat(this.viewerOptions[key][subKey]);
      } else {
        this.viewerOptions[key] = parseFloat(this.viewerOptions[key]);
      }
      this.applyInitialValues(); // Reapply all settings after any change
    },
    updateCameraSettings() {
      const cameraEl = document.querySelector('#camera');
      if (cameraEl) {
        cameraEl.setAttribute('fov', this.viewerOptions.fov);
      }
    },
    updateSkyRotation() {
      const skyEl = document.querySelector('a-sky');
      if (skyEl) {
        skyEl.setAttribute('rotation', `${this.viewerOptions.rotation[0]} ${this.viewerOptions.rotation[1]} ${this.viewerOptions.rotation[2]}`);
      }
    },
    updateCameraPosition() {
      const cameraEl = document.querySelector('#camera');
      if (cameraEl) {
        cameraEl.setAttribute('position', `${this.viewerOptions.position[0]} ${this.viewerOptions.position[1]} ${this.viewerOptions.position[2]}`);
      }
    },
    updateHotspotPosition(index) {
      this.hotspots[index].position = this.hotspots[index].position.split(' ').map(parseFloat).join(' ');
      this.hotspots[index].rotation = this.hotspots[index].rotation.split(' ').map(parseFloat).join(' ');
    },
    showHotspotDetails(index) {
      const hotspot = this.hotspots[index];
      const hotspotId = hotspot.id;
      const cookieKey = `collected-hotspot-${hotspotId}`;

      // Check if the hotspot is already collected
      const isCollected = Cookies.get(cookieKey);

      if (!isCollected) {
        // Save the hotspot as collected in the cookies
        Cookies.set(cookieKey, true, { expires: 365 }); // Expires in 1 year
        emitter.emit('hotspot-collected');
      }
      console.log('Selected Hotspot:', hotspot)
      emitter.emit("hotspot-selected", hotspot);
    },
    updateScrollLimits() {
      this.applyInitialValues(); // Reapply all settings after any change
    },
    applyRotationLimits() {
      const cameraEl = document.querySelector('#camera');
      if (!cameraEl) {
        console.error('Camera element not found.');
        return;
      }

      // Store the original tick function if not already stored
      if (!this.originalTickFunction && cameraEl.components['look-controls']) {
        this.originalTickFunction = cameraEl.components['look-controls'].tick;
      }

      const { minYaw, maxYaw, minPitch, maxPitch } = this.viewerOptions.scrollLimits;

      // Apply the scroll limits within the tick function
      cameraEl.components['look-controls'].tick = () => {
        if (this.originalTickFunction) {
          this.originalTickFunction.apply(cameraEl.components['look-controls']);
        }

        const rotation = cameraEl.getAttribute('rotation');
        const clampedYaw = THREE.MathUtils.clamp(rotation.y, minYaw, maxYaw);
        const clampedPitch = THREE.MathUtils.clamp(rotation.x, minPitch, maxPitch);

        cameraEl.setAttribute('rotation', {
          x: clampedPitch,
          y: clampedYaw,
          z: rotation.z,
        });
      };
    },
    setupZoomListeners() {
      const isDebugPanelVisible = () => this.showDebugPanel;

      // Mouse scroll zoom
      window.addEventListener('wheel', (event) => {
        if (!isDebugPanelVisible()) {
          const delta = event.deltaY * -0.05; // Adjust this multiplier for zoom speed
          this.adjustFOV(delta);
        }
      });

      // Touch zoom (pinch)
      let initialDistance = null;
      window.addEventListener('touchmove', (event) => {
        if (event.tTouches.length === 2 && !isDebugPanelVisible()) {
          const touch1 = event.touches[0];
          const touch2 = event.touches[1];
          const currentDistance = Math.hypot(
            touch2.clientX - touch1.clientX,
            touch2.clientY - touch1.clientY
          );

          if (initialDistance !== null) {
            const delta = (currentDistance - initialDistance) * 0.1;
            this.adjustFOV(delta);
          }
          initialDistance = currentDistance;
        }
      });

      window.addEventListener('touchend', () => {
        initialDistance = null;
      });
    },
    adjustFOV(delta) {
      this.viewerOptions.fov = THREE.MathUtils.clamp(
        this.viewerOptions.fov + delta,
        30, // Minimum FOV
        120 // Maximum FOV
      );
      this.updateCameraSettings();
    },
    formatLabel(key) {
      return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
    },
    isNumeric(value) {
      return !isNaN(value) && isFinite(value);
    },
    isArray(value) {
      return Array.isArray(value);
    },
    isObject(value) {
      return typeof value === 'object' && !Array.isArray(value);
    },
    getMinValue(key) {
      return key === 'fov' ? 1 : (key.includes('Yaw') ? -360 : -90);
    },
    getMaxValue(key) {
      return key === 'fov' ? 180 : (key.includes('Yaw') ? 360 : 90);
    },
  },
};
</script>

<style>
  .toggle-button {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 1001;
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 2px;
    cursor: pointer;
  }

  .toggle-button:hover {
    background-color: #0056b3;
  }

  .debug-panel {
    position: absolute;
    width: 300px;
    background: rgba(161, 161, 161, 0.8);
    padding: 15px;
    border-radius: 2px;
    z-index: 1000;
    cursor: grab;
    max-height: 80vh;
    overflow-y: auto;
  }

  .debug-panel h3 {
    margin-top: 0;
  }

  .debug-panel label {
    display: block;
    margin-top: 4px;
  }

  .debug-panel input, .debug-panel textarea {
    width: 100%;
    margin-bottom: 5px;
  }

  .array-inputs {
    margin-bottom: 10px;
  }

  .parent-container {
    background-color: rgba(255, 255, 255, 0.8);
    height: 100vh;
    width: 100vw;
  }

  .hotspot-item {
    margin-bottom: 10px;
  }
 
  .clickable {
    cursor: pointer;
  }

  a-scene {
    width: 100%;
    height: 100%;
  }

</style>
