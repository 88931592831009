<template>
  <div id="app">
    <header class="app-header">
      <div class="logo">
        <img src="@/assets/zv_logo.png" alt="Logo">
      </div>
      <div class="header-icons">
        <img src="@/assets/volume_icon.png" alt="Volume" class="icon">

        <!-- Language Selection Button -->
        <div class="language-selector" @click="toggleLanguageMenu">
          <span class="selected-language">{{ selectedLanguage }}</span>
          <transition name="fade-slide">
            <div v-if="languageMenuOpen" class="language-menu">
              <span 
                v-for="language in languages.filter(lang => lang !== selectedLanguage)" 
                :key="language" 
                @click.stop="selectLanguage(language)"
                class="language-option"
              >
                {{ language }}
              </span>
            </div>
          </transition>
        </div>

        <img src="@/assets/shop_icon.png" alt="Shop Now" class="icon">
      </div>
      <div class="hamburger-menu" @click="toggleMenu">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
    </header>
    <div v-if="menuOpen" class="mobile-menu">
      <img src="@/assets/volume_icon.png" alt="Volume" class="icon">
      <img src="@/assets/shop_icon.png" alt="Shop Now" class="icon">
    </div>
    <VRScene />
    <InventorySidebar />
    <ScrapBook />
    <HotspotDetails />
  </div>
</template>

<script>
import VRScene from './components/VRScene.vue';
import InventorySidebar from './components/InventorySidebar.vue';
import ScrapBook from './components/ScrapBook.vue';
import HotspotDetails from './components/HotspotDetails.vue';
import whitelabel from '@/assets/whitelabel.json'; 
import Cookies from 'js-cookie'; // Import the js-cookie library

export default {
  name: 'App',
  components: {
    VRScene,
    InventorySidebar,
    ScrapBook,
    HotspotDetails
  },
  data() {
    return {
      menuOpen: false,
      languages: whitelabel.languages || ['EN'], 
      selectedLanguage: this.$i18n.locale, 
      languageMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    toggleLanguageMenu() {
      this.languageMenuOpen = !this.languageMenuOpen;
    },
    selectLanguage(language) {
      this.$i18n.locale = language; // Set the i18n locale
      this.selectedLanguage = language;
      this.languageMenuOpen = false; // Close the language menu after selection
      Cookies.set('selectedLanguage', language, { expires: 365 }); // Save the selected language in a cookie for 1 year
    }
  },
};
</script>

<style>
#app {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.app-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 1001;
}

.logo img {
  height: 40px;
  width: auto; /* Maintain the aspect ratio */
}

.header-icons {
  display: flex;
  align-items: center;
}

.header-icons .icon {
  height: 30px;
  margin-left: 20px;
  cursor: pointer;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  right: 20px; /* Align the hamburger menu on the right side */
}

.hamburger-menu .bar {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 4px 0;
}

.mobile-menu {
  position: absolute;
  top: 60px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1002;
}

.mobile-menu .icon {
  margin: 10px 0; /* Add padding between the icons */
}

.language-selector {
  position: relative;
  cursor: pointer;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selected-language {
  text-decoration: underline;
  font-weight: bold;
  font-size: 16px;
}

.language-menu {
  position: absolute;
  top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Make sure the dropdown aligns with the selected language */
}

.language-option {
  padding: 5px 0;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  text-align: center;
  text-decoration: none; /* Ensure no underline by default */
}

.language-option:hover {
  text-decoration: underline; /* Only underline on hover */
}

/* Animation */
.fade-slide-enter-active, .fade-slide-leave-active {
  transition: all 0.5s ease;
}

.fade-slide-enter, .fade-slide-leave-to /* .fade-slide-leave-active in <2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}


@media screen and (max-width: 768px) {
  .logo img {
    height: 30px; /* Reduce logo size on mobile */
  }

  .header-icons {
    display: none;
  }

  .hamburger-menu {
    display: flex;
  }

  .language-selector {
    display: none; /* Hide language selector on mobile */
  }
}
</style>